<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Data Analysis - Hydrogen</h2>

      <p class="mb-3">
        Report the wavelengths of the two most intense <b>two most intense</b> spectral lines you
        observed in the hydrogen spectrum in the table below. Then, use the formula
        <stemble-latex content="$\displaystyle{E=hc/\lambda}$" /> to calculate the photon energy,
        and use the Rydberg equation to determine the energy level,
        <stemble-latex content="$n_i$" /> from which the electron dropped down to energy level 2.
      </p>

      <p class="mb-0">
        <b>Notes</b>
      </p>

      <ul class="mb-3">
        <li>
          Photon energies should be expressed using scientific notation. To express a number like
          <stemble-latex content="$1.23 \times 10^{-10}$" /> in scientific notation, write 1.23e-10
        </li>
        <li>The speed of light, <stemble-latex content="$c=2.998\times 10^8~\text{m/s}$" /></li>
        <li>
          Planck's constant,
          <stemble-latex content="$h=6.626\times 10^{-34}~\text{J}\cdot\text{s}$" />
        </li>
        <li>
          Reminder: Because the units of c are in m/s, be sure to convert your wavelength to m
          before calculating the energy so your units will cancel.
        </li>
      </ul>

      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold; text-align: center">
              Wavelength, <stemble-latex content="$\lambda$" /> (nm)
            </td>
            <td style="font-weight: bold; text-align: center">Photon Energy (J)</td>
            <td style="font-weight: bold; text-align: center">
              Energy level electron dropped from, <stemble-latex content="$n_i$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="spectralLine in spectralLines" :key="spectralLine.wavelength">
            <td class="py-2 my-2">
              <calculation-input
                v-model="inputs[spectralLine.wavelength]"
                class="centered-input"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 my-2">
              <calculation-input
                v-model="inputs[spectralLine.energy]"
                class="centered-input"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
            <td class="py-2 my-2">
              <calculation-input
                v-model="inputs[spectralLine.energyLevel]"
                class="centered-input"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'OleMiss_AtomicEmission_HydrogenResults',
  components: {
    STextarea,
    LatexNumber,
    NumberValue,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        wavelength1: null,
        energy1: null,
        ni1: null,

        wavelength2: null,
        energy2: null,
        ni2: null,
      },
      spectralLines: [
        {wavelength: 'wavelength1', energy: 'energy1', energyLevel: 'ni1'},
        {wavelength: 'wavelength2', energy: 'energy2', energyLevel: 'ni2'},
      ],
    };
  },
};
</script>
<style scoped></style>
